@use "sass:color";
@import "../../../../assets/styles/styles";

@mixin card-box($size) {
  content: "";
  height: $size;
  width: $size;
}

.planning-calendar-card {
  $self: &;

  display: flex;
  border: 1px transparent solid;

  &__container {
    width: 33px;
    height: 33px;
    position: relative;
    z-index: 1;
    align-self: flex-start;
    pointer-events: none;

    &--disabled {
      background-color: $color-calendar-grey;
    }
  }

  &__cross {
    width: 25px;
    height: 25px;
    position: absolute;
    display: flex;
    flex-direction: row;
    left: 4px;
    top: 4px;
    border: 1px solid transparent;
    background-color: rgba($color-calendar-grey, 0.4);

    #{$self}--selected & {
      border-color: black;
    }

    &::before,
    &::after {
      position: absolute;
      top: 0;
      left: calc(50% - 0.0625em);
      width: 0.125em;
      height: 23px;
      border-radius: 0.125em;
      transform: rotate(45deg);
      background: $color-grey-medium;
      content: "";
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__box {
    width: 25px;
    height: 25px;
    position: absolute;
    display: flex;
    flex-direction: row;
    border: 1px solid $color-grey-medium;

    #{$self}--selected & {
      border-color: black;
    }

    &:nth-child(1) {
      left: 4px;
      top: 4px;
      z-index: 2;
    }

    &:nth-child(2) {
      left: 0;
      top: 0;
      z-index: 1;
    }

    &:nth-child(3) {
      right: 0;
      bottom: 0;
      z-index: 3;
    }
  }

  &__availability {
    width: 50%;
    height: 100%;

    /* Configurability */
    &--fully-configurable {
      background-color: $color-calendar-configurable-fully;
    }

    &--partially-configurable {
      background-color: $color-calendar-configurable-partially;
    }

    &--not-configurable {
      background-color: $color-calendar-configurable-none;
    }

    &--empty {
      background-color: $color-calendar-grey;
    }
  }
}
