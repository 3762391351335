.o-info {
  position: relative;
  cursor: help;
  display: flex;
  align-items: center;

  &::after {
    content: "\e90e";
    font-family: $font-icomoon;
    font-size: 14px;
    color: $color-grey-dark;
    margin-left: $global-margin-xs;
  }

  &::before {
    content: attr(aria-label);
    position: absolute;
    display: block;
    top: 50%;
    right: -10px;
    transform: translate(100%, -50%);
    z-index: 1;
    white-space: pre-wrap;
    background-color: $color-white;
    width: max-content;
    max-width: 500px;
    box-shadow: $box-shadow;
    padding: $global-padding-md;
    opacity: 0;
    transition: $transition;

    @media (max-width: 1800px) {
      width: 300px;
      top: 0;
      right: auto;
      left: 0;
      transform: translate(-350px, 20px);
    }
  }

  &:hover,
  &:focus {
    &::before {
      opacity: 1;
    }
  }
}
