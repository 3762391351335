$color-black: #111827;
$color-primary: #c8102e;
$color-primary-light: #ce122d;
$color-green: #0e762b;
$color-blue: #05539d;
$color-calendar-orange: #ff7f00;
$color-secondary-1: #263238;
$color-secondary-1-opac: rgba(#263238, 0.5);
$color-secondary-2: #44565f;
$color-secondary-3: #829198;
$color-secondary-4: #9ca3af;
$color-accent-1: #325175;
$color-accent-2: #7d93ad;
$color-accent-3: #ffc107;
$color-green-primary: #27ae60;
$color-grey-dark: #999;
$color-grey-medium: #ccc;
$color-grey-light: #e4e4e4;
$color-grey-ultralight: #f5f5f5;
$color-white: #fff;
$color-grey-transparent: rgb(217 218 219 / 40%);
$color-azur-light: #f4fffb;
$color-lime: #36c304;

// Planning Calendar
$color-calendar-configurable-fully: #699b6c;
$color-calendar-configurable-partially: #f7b542;
$color-calendar-configurable-none: #bb3323;
$color-calendar-grey: #e7e4e0;
