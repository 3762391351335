@use "sass:color";
@import "../../../../assets/styles/styles";

.planning-calendar-months {
  display: grid;
  grid-template-columns: repeat(4, 40px);
  row-gap: 15px;
  column-gap: 5px;
  padding: 10px;
  justify-content: center;

  &__month {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;

    &--inactive {
      opacity: 0.4;
      cursor: not-allowed;
    }

    &--clickable {
      cursor: pointer;

      // External reference to child class on hover of parent
      &:hover > .planning-calendar-card {
        border: 1px gray dotted;
      }
    }
  }

  &__month-label {
    display: flex;
    align-items: center;
    font-weight: 400;
    text-align: center;
    font-size: 14px;
    transition: $transition;

    &--selected {
      font-weight: 600;
    }
  }
}
