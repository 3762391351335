@import "../../../assets/styles/styles";

.header {
  background-color: $color-secondary-1;

  @include mq($until: tablet) {
    width: 1100px;
  }

  &__content-wrapper {
    @extend %o-row;

    height: $header-height;
    display: flex;
    align-items: center;
    z-index: 50;
  }

  &__logo-link {
    height: $header-height;

    &:focus,
    &:hover,
    &:active {
      border: none;
      outline: 0;
    }
  }

  &__logo {
    height: $header-height;
    width: auto;
  }

  &__searchbar-wrapper {
    margin-left: 127px;

    @include mq($until: tablet) {
      margin-left: 17px;
    }
  }

  &__dealership-filter-wrapper {
    width: 270px;
    margin-left: 80px;

    @include mq($until: tablet) {
      width: 200px;
      margin-left: 50px;
    }

    // Customize lib.
    .rmsc {
      .item-renderer input {
        accent-color: $color-secondary-1;
      }

      .dropdown-container {
        border: 1px solid #999;
        background-color: #263238;
        border-radius: 30px;
        z-index: 20;

        &:focus-within {
          box-shadow: none;
          border-color: #999;
        }
      }

      .dropdown-heading {
        height: 33px;
      }

      .dropdown-heading-value {
        font-size: 13px;
        font-style: italic;

        .gray,
        span {
          color: $color-white;
        }
      }

      .search {
        padding: 0;

        input {
          font-size: 13px;
          background-color: $color-white;
          border: none;
          border-radius: 0;
        }
      }

      .item-renderer {
        font-size: 13px;
        color: $color-black;
        display: flex;
        align-items: center;

        span {
          margin-top: 2px;
        }
      }

      .no-options {
        font-size: 13px;
      }
    }
  }

  &__actions-wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .button--alert {
      margin-left: 70px;
    }

    .header-dropdown {
      margin-left: 70px;
    }
  }

  &__navigation-wrapper {
    background-color: $color-grey-ultralight;
    z-index: 49;
    border-bottom: 1px solid $color-grey-medium;
  }

  &__navigation {
    @extend %o-row;

    display: flex;
  }

  &__navigation-multi-items-text {
    background: none;
    outline: 0;
    color: $color-secondary-3;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    position: relative;
    transition: $transition;
    padding: 16px 50px;
    display: flex;
    align-items: center;

    &::after {
      content: "\e908";
      display: inline-block;
      font-family: $font-icomoon;
      font-size: 6px;
      margin-left: 6px;
      color: $color-secondary-3;
      transition: $transition;
    }
  }

  &__navigation-multi-items-list {
    position: absolute;
    left: 25px;
    width: calc(100% - 50px);
    bottom: 0;
    transform: translateY(100%);
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    max-height: 0;
    transition: $transition;
    z-index: 50;
  }

  &__navigation-multi-items {
    position: relative;

    &--opened {
      .header__navigation-multi-items-list {
        max-height: 100vh;
      }

      .header__navigation-multi-items-text::after {
        transform: rotate(180deg);
      }
    }
  }

  &__navigation-multi-items-list-item {
    color: $color-secondary-3;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    padding: 10px 25px;
    text-decoration: none;
    transition: $transition;
    border: 1px solid $color-grey-medium;
    border-top: none;

    &:focus,
    &:active,
    &:hover {
      color: $color-black;
      text-decoration: none;
      outline: 0;
    }
  }

  &__navigation-link {
    display: block;
    color: $color-secondary-3;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    padding: 16px 0;
    position: relative;
    transition: $transition;

    &,
    &:focus,
    &:active,
    &:hover {
      color: $color-secondary-3;
      text-decoration: none;
      outline: 0;
    }

    & + & {
      margin-left: 50px;
    }

    &:hover {
      color: $color-black;
    }

    i {
      font-size: 28px;
      line-height: 0;
      vertical-align: middle;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      right: 50%;
      height: 2px;
      width: 0;
      background-color: $color-primary;
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }

    &--active {
      color: $color-black !important; 

      &::after {
        width: 100%;
        right: 0;
        opacity: 1;
      }
    }
  }
}
