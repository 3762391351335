@import "../../../assets/styles/styles";

.checkbox {
  display: inline-block;

  &__input {
    position: relative;
    appearance: none;
    width: 16px;
    height: 16px;
    background-color: $color-white;
    border: 1px solid $color-secondary-1;
    border-radius: $border-radius-xs;
    outline: none;
    cursor: pointer;
    overflow: hidden;

    &--checked {
      background-color: $color-secondary-1;

      &::before {
        content: "\2714";
        font-size: 20px;
        color: $color-white;
        position: absolute;
        right: -2px;
        top: -5px;
      }
    }
  }
}
