@import "../../../assets/styles/styles";

.planning-results {
  padding-left: 30px;

  &__filters-container {
    position: relative;
    padding-top: 3px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    margin-left: -12px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &::before {
      position: absolute;
      left: 12px;
      top: 0;
      content: "";
      display: block;
      width: calc(100% - 12px);
      height: 1px;
      background-color: rgba($color-white, 0.2);
    }

    &::after {
      position: absolute;
      left: 12px;
      bottom: 0;
      content: "";
      display: block;
      width: calc(100% - 12px);
      height: 1px;
      background-color: rgba($color-white, 0.2);
    }
  }

  &__date-switcher-container {
    padding: 0 15px;
    margin-bottom: 15px;
  }

  &__content {
    width: 100%;
  }

  &__scroll-container {
    overflow: hidden !important;
  }
}
