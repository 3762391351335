@import "../../../assets/styles/styles";

.radio {
  &__option-container {
    min-height: 30px;
    display: flex;
    align-items: center;

    & + & {
      margin-top: 6px;
    }
  }

  &__option-input {
    appearance: none;
    position: relative;
    width: 16px;
    height: 16px;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: $color-white;
      border: 1px solid $color-secondary-1;
    }

    &::after {
      content: "";
      position: absolute;
      top: 4px;
      left: 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: block;
      background-color: $color-secondary-1;
      transform: scale(0);
      transition: $transition;
    }

    &:checked::after {
      transform: scale(1);
    }
  }

  &__option-label {
    @extend %label;

    margin-left: $global-margin-sm;
  }
}
