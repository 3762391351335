@import "../../../assets/styles/styles";

.content {
  padding: $global-padding-xl;
}

.form {
  display: flex;
  gap: $global-padding-sm;
  flex-direction: column;

  ul {
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      align-items: center;

      label {
        width: 100px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.28;
      }
    }
  }
}

.concession-wrapper {
  display: flex;
  gap: 10px;
  padding-top: $global-padding-xl;
  flex-wrap: wrap;
}

.concession {
  transition: $transition;
  width: 370px;
  border-radius: $global-radius;
  padding: $global-padding-md;
  box-shadow:
    rgb(0 0 0 / 10%) 0 10px 15px -3px,
    rgb(0 0 0 / 5%) 0 4px 6px -2px;
  border: 1px solid $color-grey-light;

  &__header {
    display: flex;
    gap: $global-padding-sm;
    align-items: center;
    justify-content: space-between;

    :last-child {
      color: $color-accent-3;

      &::after {
        content: "\e917";
        display: inline-block;
        font-family: $font-icomoon;
        font-size: 20px;
        color: inherit;
      }
    }
  }

  &__adress {
    color: $color-secondary-2;
    padding-bottom: $global-padding-md;
    margin-top: 10px;
    text-transform: capitalize;
  }

  &__name {
    font-size: $global-font-size;
    font-weight: 700;
    color: $color-secondary-2;
    min-height: 40px;
    display: flex;
    align-items: center;
  }
}

.profile-svg {
  width: 60px;
  fill: blue;
}

.label {
  display: flex;
  gap: $global-padding-sm;

  &--margin-top {
    margin-top: $global-padding-xl;
  }

  &__icon {
    &::after {
      content: "\e917";
      display: inline-block;
      font-family: $font-icomoon;
      font-size: 30px;
      color: inherit;
    }

    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $color-accent-3;
    display: flex;
    color: $color-white;
    justify-content: center;
    align-items: center;

    &--profile::after {
      content: "\e918";
      color: $color-white;
    }
  }

  &__title {
    font-size: 40px;
    color: $color-accent-1;
  }
}

.select-container {
  display: flex;
  align-items: center;

  &--static {
    margin: 16px 0;
  }

  &--capitalize {
    text-transform: capitalize;
  }
}

.select-container .button {
  margin-left: 20px;
}
