@import "../../assets/styles/styles";

.general-search-page {
  background-color: $color-grey-ultralight;
  min-height: calc(100vh - ($header-height-with-nav + $footer-height));

  &__top-page {
    background-color: $color-secondary-1;
  }

  &__top-page-content {
    @extend %o-row;
  }

  &__input-container {
    padding: 38px 0;
    width: 240px;
  }

  &__tabs {
    display: flex;
    background-color: $color-grey-ultralight;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 20px;
  }

  &__results-nb {
    font-size: 10px;
    margin-left: 4px;
  }

  &__tab {
    display: flex;
    align-items: center;
    color: $color-secondary-3;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    padding: 16px 0;
    position: relative;
    transition: $transition;
    margin-left: 50px;
    background-color: $color-grey-ultralight;

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      right: 50%;
      height: 2px;
      width: 0;
      background-color: $color-primary;
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }

    &:first-child {
      margin-left: 25px;
    }

    &--active {
      color: $color-black !important; // visual bug on chrome *_*

      &::after {
        width: 100%;
        right: 0;
        opacity: 1;
      }
    }
  }

  &__results {
    background-color: $color-grey-ultralight;
    min-height: calc(100vh - ($header-height-with-nav + $footer-height + 172px + 126px + 48px));
  }

  &__body-page {
    background-color: $color-grey-ultralight;
    padding-bottom: 38px;
  }

  &__body-page-content {
    @extend %o-row;
  }

  &__results-header {
    display: flex;
    justify-content: stretch;
  }

  &__results-header-title {
    background: #f5f5f5;
    border: 1px solid #e4e4e4;
    text-transform: uppercase;
    color: #829198;
    font-weight: 700;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 20px;
    line-height: 16px;
    vertical-align: middle;
    font-size: 14px;
    text-align: center;
    border-bottom: 1px solid $color-grey-light;
  }

  &__results-wrapper {
    display: flex;
    flex-direction: column;
  }
}
