@import "../../../assets/styles/styles";

.commentary-modal {
  background-color: $color-white;
  transition: $transition;
  position: relative;
  padding: 15px;
  border-radius: 6px;

  &__group-label {
    padding-bottom: 8px;
  }

  &__commentary-select {
    color: $color-secondary-4;
    padding-bottom: 8px;
  }

  &__save-btn-container {
    text-align: right;
  }

  &__save-btn {
    padding: 5px 10px;
    border: 1px solid $color-secondary-4;
    border-radius: 8px;
    margin: 10px;

    &:disabled {
      background-color: $color-grey-medium;
      opacity: 0.5;
    }
  }

  .text-input__input {
    width: 350px;
    margin-bottom: 15px;
  }
}
