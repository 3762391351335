@import "../../../assets/styles/styles";

.config-wrapper {
  &__block {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__content {
    white-space: pre-line;
    margin-bottom: 1px;
    display: flex;
    background-color: $color-white;
    padding: $global-padding-lg $global-padding-md;
    align-items: center;
  }

  &__container {
    padding: 0 $global-padding-sm;
    display: flex;
    flex-direction: column;

    &--mark {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 13px;
    }

    &--price {
      font-size: $global-font-size;
      font-weight: 600;
    }

    &--img {
      width: 150px;
      height: 70px;
      background: url("../../../assets/img/camping.png");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &__divider {
    height: 10px;
    background-color: $color-secondary-2;
    display: flex;
    justify-content: center;

    :first-child {
      height: 10px;
      width: 2px;
      background-color: $color-white;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  overflow: hidden;
  background-color: $color-white;

  &__list {
    transition: $transition;

    &--visible {
      max-height: 1000px;
    }

    &--hidden {
      max-height: 0;
    }
  }
}
