@import "../../assets/styles/styles";

.account-statement {
  background-color: $color-secondary-1;
  min-height: calc(100vh - 442px);

  &__actions-wrapper {
    display: flex;
    padding-bottom: 20px;
    align-items: flex-start;
    flex-direction: column;
  }

  .account-statement__actions-wrapper {
    margin-right: 20px;
  }

  &__row {
    display: flex;
  }

  &__main-wrapper {
    @extend %o-row;

    padding: 38px 0;
  }

  &__label {
    margin-bottom: 8px;
    color: $color-white;
    font-size: 16px;
  }

  &__select-wrapper {
    position: relative;

    &::after {
      font-family: $font-icomoon;
      content: "\e908";
      display: inline-block;
      font-size: 8px;
      color: $color-white;
      position: absolute;
      right: 20px;
      top: 18px;
    }
  }

  &__select {
    border: 1px solid #999;
    background-color: #263238;

    // border-radius: 30px;
    color: $color-white;
    width: 370px;
    height: 40px;
    padding: 0 3px;
    appearance: none;

    &::after {
      font-family: $font-icomoon;
      content: "\e908";
      display: inline-block;
      font-size: 18px;
      color: $color-white;
    }
  }

  .no-data {
    font-size: 16px;
    color: $color-white;
    margin-top: 16px;
  }
}
