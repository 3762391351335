@import "../../../assets/styles/styles";
/* stylelint-disable */

.alerts-page {
  font-size: 16px;
  height: 70vh;
  overflow-y: scroll;

  &__header {
    display: flex;
    font-weight: 600;
    height: 58px;
    padding: 10px;
    position: sticky;
    top: 0;
    background-color: $color-white;
    border-bottom: 1px solid $color-grey-medium;
  }

  &__checkbox {
    margin: 0 30px;
    width: 16px;
  }

  &__category {
    color: $color-grey-dark;
    padding: 5px;
    margin: 0 15px;
    cursor: pointer;
    background-color: white;
    font-weight: 700;

    .dot {
      height: 7px;
      width: 7px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
      vertical-align: super;

      &.--warning {
        background-color: $color-primary;
      }

      &.--info {
        background-color: $color-calendar-orange;
      }

      &.--my-alerts {
        background-color: $color-blue;
      }
    }

    &:focus,
    &:active {
      border-bottom: 3px solid $color-secondary-1;
      transition: none;
    }

    &--selected {
      color: $color-secondary-1;
      border-bottom: 3px solid $color-secondary-1;
    }
  }

  &__action {
    border-radius: 10px;
    padding: 0 15px;
    margin: 0 20px 0 50px;
    font-weight: 500;

    &.read {
      border: 2px solid $color-lime;

      &:hover {
        background-color: $color-lime;
      }
    }

    &.delete {
      border: 2px solid $color-primary-light;

      &:hover {
        background-color: $color-primary-light;
      }

      &::before {
        font-family: $font-icomoon;
        content: "\e92c";
        display: inline;
        font-size: 16px;
        color: $color-black;
        margin-right: 10px;
      }
    }
  }

  &__body {
    min-height: 500px;
  }

  &__line {
    height: 85px;
    border-bottom: 1px solid $color-grey-light;
    padding: 10px 36px 10px 4px;
    display: flex;

    &.false {
      background-color: $color-azur-light;
      font-weight: 700;
    }
  }

  &__line-category {
    width: 5px;
    height: 100%;
    border-radius: 5px;

    &.urgent {
      background-color: $color-primary;
    }

    &.notification {
      background-color: $color-calendar-orange;
    }

    &.alerte {
      background-color: $color-blue;
    }
  }

  &__line-text {
    width: 100%;

    &--color {
      color: $color-primary;
    }
  }

  &__line-message {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
  }

  &__line-info {
    font-size: 13px;

    &--date {
      color: $color-secondary-2;
    }

    &--unread {
      font-weight: 700;
      color: $color-lime;
      margin-left: 10px;
    }
  }
}

/* stylelint-enable */
