@import "../../../assets/styles/styles";
/* stylelint-disable */
// TABLEAUX

.table_AccountStatement {
  overflow: auto;
  max-height: 70vh;
  background-color: $color-white;
  max-width: fit-content;
}


.table__group {

  top: 0;
  min-height: 53px;
  z-index: 2; /* Ajout de z-index pour s'assurer que les deux premières colonnes restent au-dessus des autres */

  &:hover {
    background-color: #f0f0f0;
    color: #000;
    transition: background-color 0.3s, color 0.3s;
  }
}


.table__header {
  position: sticky;
  z-index: 12

}


.table__title,
.table__item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  padding: 0 20px;
  line-height: 16px;
  vertical-align: middle;
  font-size: 14px;
  text-align: center;
  border-bottom: 1px solid $color-grey-light;

  &.accountStatement-piece,
  &.accountStatement-label,
  &.accountStatement-date,
  &.accountStatement-debit,
  &.accountStatement-credit,
  &.accountStatement-total,
  &.accountStatement-balance {
    width: 200px;
  }
}

.table__line.total-line {
  position: static;
  justify-content: end;
  background-color: #bbb;
  width: 1400px;

  .table__item {
    border-bottom: none;

    &.total {
      text-transform: uppercase;
    }
  }
}

.table__line {
  &.clickable {
    cursor: pointer;
  }
}


