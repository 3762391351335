@import "../../../assets//styles/styles.scss";

.vignette-config {
  display: flex;
  flex-direction: column;

  &__img {
    height: 200px;
    background-image: url("../../../assets//img/camping.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: 0 $global-padding-md;
  }

  &__content {
    background-color: $color-secondary-2;
    padding: $global-padding-lg;
  }

  &__box {
    padding-bottom: $global-padding-md;
    color: $color-white;

    &--mark {
      font-size: $global-font-size;
      text-transform: uppercase;
      font-weight: 700;
    }

    &--model {
      color: $color-grey-light;
    }
  }

  &__picture {
    padding: $global-padding-lg;
  }

  &__infos {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-grey-light;

    &--specs {
      flex: 1;
      display: flex;
      gap: 17px;
    }

    &--price {
      text-align: right;
      flex: 1;

      :first-child {
        font-style: italic;
      }

      :last-child {
        font-weight: 600;
        font-size: 32px;
      }
    }
  }
}
