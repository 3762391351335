.planning-calendar-legend {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  margin-top: 20px;

  &__container {
    display: flex;
    flex-direction: row;
    gap: 9px;
  }

  &__label {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    vertical-align: center;
  }
}
