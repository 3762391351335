@import "../../../assets/styles/styles";

.form-search {
  position: relative;

  input[type].form-search__input {
    min-height: 44px;
    border: 1px solid $color-grey-light;
    width: calc(100% - 8px);
    margin: 0 4px;
    padding-left: 44px;

    &,
    &:active,
    &:focus,
    &:visited {
      outline: none;
    }

    &::placeholder {
      font-style: italic;
    }
  }

  &::before {
    content: "\e903";
    display: block;
    position: absolute;
    top: 10px;
    left: 22px;
    font-family: $font-icomoon;
    font-size: 18px;
    color: $color-black;
  }
}
