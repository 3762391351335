@import "../../../assets/styles/styles";

.search-filters {
  width: 300px;
  background-color: $color-white;
  padding-bottom: 45px;
  border-radius: 10px;

  &__header {
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__form-title {
    font-size: $font-size-md;
    font-weight: 600;
    line-height: $line-height-md;
  }

  &__reset-button {
    min-width: 0;
    padding: 0;
    background: none;
    display: flex;
    align-items: center;

    &::after {
      content: "\e914";
      text-decoration: none;
      display: inline;
      font-family: $font-icomoon;
      font-size: 18px;
      margin-left: 10px;
    }
  }

  &__reset-button-text {
    text-decoration: underline;
    font-weight: 400;
    line-height: 1.42;
    font-size: 14px;
    font-style: normal;
    margin-left: 86px;
  }

  &__vehicle-types {
    border-top: 1px solid $color-grey-light;
    border-bottom: 1px solid $color-grey-light;
    padding: 20px 10px;
    display: flex;
    justify-content: center;
  }

  &__vehicle-type-button {
    width: 116px;
    height: 78px;
    border-radius: 10px;
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: $line-height-md;
    font-size: $font-size-md;
    font-weight: 600;
    color: $color-secondary-1;
    transition: $transition;
    opacity: 0.5;

    &,
    &:hover,
    &:active,
    &:focus {
      border: 1px solid transparent;
    }

    &::before {
      content: "\e916";
      font-family: $font-icomoon;
      font-size: 35px;
      margin-bottom: 6px;
      font-weight: 400;
    }

    &:last-child {
      margin-left: 20px;

      &::before {
        content: "\e915";
      }
    }

    &--active {
      opacity: 1;

      &,
      &:hover,
      &:active,
      &:focus {
        border: 1px solid $color-secondary-1;
      }
    }
  }

  &__form-section-header {
    width: 100%;
    outline: 0;
    background: none;
    position: relative;
    text-align: left;

    &::after {
      content: "\e900";
      display: block;
      font-family: $font-icomoon;
      font-size: $font-size-md;
      position: absolute;
      top: 16px;
      right: 16px;
      transform: rotate(90deg);
      transition: $transition;
    }
  }

  &__form-section-title {
    font-size: $font-size-md;
    line-height: 20px;
    font-weight: 400;
    color: $color-black;
    padding-left: 30px;
    padding-bottom: 16px;
    padding-top: 16px;
    position: relative;
    display: block;
    transition: $transition;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      background-color: $color-grey-light;
      bottom: 0;
      left: 0;
    }

    &--active {
      font-weight: 700;
    }
  }

  &__form-section-content {
    overflow-y: hidden;
    max-height: 100vh;
    transition: $transition;
    padding-bottom: 24px;

    .form-search {
      margin-bottom: 24px;
    }
  }

  &__form-section {
    padding: 0 16px;

    .search-filters__form-section-title--search::after {
      opacity: 0;
    }

    &--hidden {
      .search-filters__form-section-header {
        &::after {
          transform: rotate(270deg);
        }
      }

      .search-filters__form-section-title--search::after {
        opacity: 1;
        transition: $transition;
        transition-delay: 0.3s;
      }

      .search-filters__form-section-content {
        max-height: 0;
        transition: $transition;
        padding-bottom: 0;
      }
    }
  }

  &__form-group-container {
    max-height: 400px;
    overflow-y: auto;
  }

  &__form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 14px;

    &:first-child {
      padding-top: 24px;
    }

    & + & {
      margin-top: 10px;
    }

    &--value-switch {
      align-items: flex-start;
      justify-content: center;
      padding: 0 16px;
    }
  }

  &__value-label-container {
    display: flex;
    align-items: center;
    padding-left: 24px;

    .checkbox {
      margin-top: 5px;
    }
  }

  &__form-label {
    font-size: $font-size-sm;
    font-weight: 400;
    line-height: 18px;
    color: $color-secondary-1;
    margin-left: 16px;
  }

  &__form-filter-number {
    font-size: $font-size-xs;
    line-height: 16px;
    font-weight: 400;
    color: $color-secondary-3;
  }

  &__value-switch-all-container {
    display: flex;
  }

  &__value-switch-values-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: -16px;
    flex: 1;

    .value-switch {
      margin-left: 16px;
      margin-top: 16px;
    }
  }
}
