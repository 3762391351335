@import "../../../assets/styles/styles";

.quote-print {
  background-color: $color-white;
  transition: $transition;
  position: relative;
  padding: 15px;
  border-radius: 6px;

  &__description {
    display: flex;
    width: 266px;
    height: 80px;
    left: 996px;
    top: 42px;
    padding-right: 10px;
    margin-top: 10px;
    color: $color-black;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 30px;
  }

  &__text-validity {
    width: 178px;
    height: 2px;
    display: flex;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $color-black;
  }

  &__button-validity-content {
    margin-bottom: 10px;
    width: 270px;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $color-white;
    color: $color-accent-2;
  }

  &__button-save {
    width: 266px;
    height: 50px;
    display: flex;
    align-items: center;
    margin-left: 4px;
  }
}
