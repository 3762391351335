@import "../../assets/styles/styles";

.login-page {
  display: flex;
  align-items: stretch;
  min-height: 100vh;

  &__illustration {
    flex: 1;
    background-image: url("../../assets/img/login-illustration.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__functionnal-wrapper {
    flex: 1;
    background-color: $color-secondary-1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__login-card {
    padding: $global-padding-xl;
    background-color: $color-white;
    width: 500px;
    border-radius: $global-radius;
    text-align: center;
    box-shadow: $box-shadow;
  }

  &__logo {
    width: 300px;
    height: auto;
  }

  &__description {
    @extend %paragraph-medium;

    margin-top: $global-margin-lg;
  }

  &__login-button-wrapper {
    margin-top: $global-margin-lg;
  }
}
