@import "./../../../assets/styles/styles";

.settings-page {
  display: flex;
  flex-direction: column;
  padding: 100px;

  &__header {
    padding-left: 285px;
    display: flex;
  }

  &__title {
    flex-direction: column;

    //  margin-right: 190px;
    margin-left: 120px;
    margin-bottom: 30px;
    font-size: 17px;
    font-weight: 600;

    &.second {
      margin-left: 100px;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
  }

  &__group-header-all {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 15px;
  }

  &__group-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    background-color: $color-calendar-grey;
    padding: 15px;
    position: relative;
    border-top: 2px solid $color-secondary-1;
    border-bottom: 2px solid $color-secondary-1;
  }

  &__group-title {
    font-size: 16px;
    font-weight: bold;
    margin-right: 240px;
  }

  &__group-title-notification {
    font-size: 16px;
    font-weight: bold;
    margin-right: 170px;
  }

  &__toggle-buttons {
    display: flex;
    align-items: center;
  }

  &__toggle-button {
    margin-right: 200px;
  }

  &__toggle-button-category {
    margin-right: 200px;
  }

  &__notifications {
    display: flex;
    flex-direction: column;
  }

  &__categories {
    margin-left: 10px;
  }

  &__toggle-buttons-category {
    display: flex;
    align-items: center;
  }

  &__category {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__category-title {
    font-size: 16px;
    font-weight: bold;
    width: 300px;
    display: flex;
  }

  &__toggle-containers {
    display: flex;
    margin-left: 100px;
  }

  &__toggle-container {
    &.second {
      margin-left: 150px;
    }
  }

  /* Style for alerts rows */
  &__alerts {
    display: flex;
    flex-direction: column;
  }

  &__group-title-alert {
    font-size: 16px;
    font-weight: bold;
    margin-right: 180px;
  }

  &__toggle-buttons-alert {
    display: flex;
    align-items: center;
  }

  &__toggle-button-alert {
    margin-right: 205px;
  }

  &__toggle-button-label {
    margin-right: 10px;
  }
}
