@import "../../../assets/styles/styles";

.modal-dealer-info {
  &__title {
    color: $color-accent-1;
    font-size: 26px;
    font-weight: 700;
    text-align: left;
    margin: 10px 0;
  }

  &__detail {
    background-color: $color-white;
    border-radius: 8px;
    padding: 25px 30px 15px;
    text-transform: uppercase;
    line-height: 2;

    .big {
      font-size: 20px;
      font-weight: 700;
    }

    .contact {
      text-transform: none;
      margin-top: 20px;

      &__email {
        color: blue;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;

    button {
      margin: 20px 5px;
      padding: $global-padding-sm $global-padding-md;
      font-size: $font-size-lg;
      font-weight: 600;
      text-align: center;
      min-width: 180px;
      border-radius: $border-radius-lg;

      &.cancel-btn {
        &,
        &:active,
        &:focus {
          background-color: $color-grey-medium;
          color: $color-secondary-1;
        }

        &:hover {
          background-color: $color-grey-dark;
          color: $color-secondary-1;
        }
      }
    }
  }
}
