@import "../../../assets/styles/styles";

.button {
  padding: $global-padding-md $global-padding-md;
  font-size: $font-size-lg;
  font-weight: 600;
  text-align: center;
  min-width: 266px;
  border-radius: $border-radius-md;

  &--primary {
    &,
    &:active,
    &:focus {
      background-color: $color-accent-1;
      color: $color-white;
    }

    &:hover {
      color: $color-white;
      background-color: $color-accent-2;
    }

    &:disabled {
      color: $color-white;
      background-color: $color-accent-2;
    }
  }

  &--secondary {
    &,
    &:active,
    &:focus {
      background-color: $color-white;
      border: 1px solid $color-accent-1;
      color: $color-accent-1;
    }

    &:hover {
      color: $color-accent-2;
      border: 1px solid $color-accent-2;
    }

    &:disabled {
      color: $color-accent-2;
      border: 1px solid $color-accent-2;
    }
  }

  &--tertiary {
    &,
    &:active,
    &:focus {
      background-color: $color-grey-ultralight;
      color: $color-secondary-1;
    }

    &:hover {
      background-color: $color-grey-medium;
      color: $color-secondary-1;
    }

    &:disabled {
      background-color: $color-grey-medium;
      color: $color-secondary-1;
    }
  }

  &--rounded {
    padding: 12px $global-padding-md;

    &,
    &:active,
    &:focus {
      background-color: $color-secondary-1;
      border-radius: $border-radius-lg;
      color: $color-white;
    }

    &:hover {
      color: $color-white;
      background-color: $color-secondary-3;
    }
  }

  &--chevron-right {
    &::after {
      content: "\e900";
      display: inline-block;
      font-family: $font-icomoon;
      font-size: 12px;
      margin-left: 15px;
      transition: $transition;
      color: inherit;
    }

    &:hover::after {
      transform: translateX(2px);
    }
  }

  &--alert {
    padding: 10px;
    min-width: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &,
    &:hover,
    &:active,
    &:focus {
      border: none;
    }

    &::before {
      content: "\e902";
      display: inline-block;
      font-family: $font-icomoon;
      font-size: 24px;
      transition: $transition;
      color: $color-white;
    }

    &:hover {
      &::before {
        animation: u-tilt-shaking 0.4s ease;
      }
    }

    .button__alerts-number {
      position: absolute;
      top: 5px;
      right: 5px;
      background-color: $color-primary;
      color: $color-white;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 10px;
      line-height: 1;
      font-family: $font-family-tertiary;
      text-align: center;

      &::after {
        content: "+";
        display: inline-block;
        margin-top: 1px;
        font-size: 9px;
      }
    }
  }

  &--lightsmall {
    padding: 13px 15px;
    color: $color-secondary-1;
    background-color: $color-grey-ultralight;
    font-size: $font-size-md;
    font-weight: 600;
    line-height: $line-height-sm;
    min-width: auto;

    &:hover {
      background-color: $color-grey-light;
    }
  }

  &--darksmall {
    padding: 13px 15px;
    background-color: $color-secondary-1;
    color: $color-grey-ultralight;
    font-size: $font-size-md;
    font-weight: 600;
    line-height: $line-height-sm;
    min-width: auto;

    &:active {
      color: $color-grey-ultralight;
    }

    &:hover {
      background-color: $color-grey-light;
    }

    &:focus {
      color: $color-grey-ultralight;
    }
  }

  &--primary-small {
    padding: 13px  $global-padding-sm;
    font-size: $font-size-md;
    font-weight: 600;
    line-height: $line-height-sm;
    background-color: $color-accent-1;
    color: $color-white;
    border-radius: $border-radius-lg;
    min-width: 150px;

    &:hover,
    &:focus,
    &:active {
      background-color: $color-secondary-1;
      color: $color-white;
    }

    &:disabled {
      background-color: $color-grey-medium;
    }
  }

  &--cancel {
    padding: 13px $global-padding-sm;
    font-size: $font-size-md;
    font-weight: 600;
    line-height: $line-height-sm;
    min-width: auto;
    background-color: $color-white;
    color: $color-grey-dark;
    border-radius: $border-radius-lg;
    display: inline-flex;
    align-items: center;
    text-decoration: underline;
    position: relative;
    padding-left: $global-padding-xl;

    &:hover,
    &:focus,
    &:active {
      background-color: $color-white;
      color: $color-grey-dark;
      text-decoration: underline;
    }

    &::before {
      left: 15px;
      top: 50%;
      content: "\e90d";
      text-decoration: none;
      display: inline;
      position: absolute;
      font-family: $font-icomoon;
      font-size: 20px;
      margin-right: $global-margin-xs;
      line-height: 0;
    }
  }

  &--edit {
    background: none;
    min-width: auto;
    padding: 0;

    &::before {
      content: "\e911";
      text-decoration: none;
      display: inline;
      font-family: $font-icomoon;
      font-size: 20px;
      color: $color-black;
    }
  }

  &--cancel-inline {
    background: none;
    min-width: auto;
    padding: 0;
    margin-left: 5px;

    &::before {
      content: "\e90d";
      text-decoration: none;
      display: inline;
      font-family: $font-icomoon;
      font-size: 20px;
      color: $color-black;
    }
  }

  &--delete {
    background: none;
    min-width: auto;
    padding: 0;

    &::before {
      content: "\e912";
      text-decoration: none;
      display: inline;
      font-family: $font-icomoon;
      font-size: 20px;
      color: $color-black;
    }
  }

  &--save {
    background: none;
    min-width: auto;
    padding: 0;

    &::before {
      content: "\e913";
      text-decoration: none;
      display: inline;
      font-family: $font-icomoon;
      font-size: 20px;
      color: $color-black;
    }
  }
}
