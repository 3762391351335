@import "../../../assets/styles/styles";

.filter-label {
  font-size: 16px;
  background-color: #f5f5f5;
  color: #263238;
  border-radius: 4px;
  padding: 4px 5px;
  font-weight: 500;
  margin-left: 12px;
  margin-top: 12px;
}
