@import "../../../assets/styles/styles";

.search-similar-results {
  margin-top: 50px;

  &__header {
    color: $color-white;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    padding-bottom: 13px;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    margin-bottom: 33px;
  }

  &__content-raw {
    display: flex;
    flex-direction: column;
  }

  &__content-vignette {
    display: flex;
    flex-wrap: wrap;
    margin-left: -22px;
    margin-top: -22px;
  }
}
