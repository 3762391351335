@import "../../../assets/styles/styles";

.contacts {
  padding: $global-padding-xl;
  padding-top: calc($global-padding-xl - 30px);
  padding-left: calc($global-padding-xl - 30px);
  display: flex;
  flex-wrap: wrap;

  .card {
    width: 250px;
    margin-top: 10px;
    margin-left: 10px;
    min-height: 238px;

    &:hover {
      transform: scale(1.05);
    }

    transition: $transition;
    border-radius: $border-radius-sm;
    padding: $global-padding-xl 0;
    box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &__logo {
      width: 100px;
      height: 60px;
      object-fit: contain;
      overflow: hidden;
      margin-bottom: $global-margin-sm;
    }

    &__mark {
      text-transform: uppercase;
      color: $color-accent-1;
      margin-bottom: $global-padding-lg;
    }

    .agent {
      display: flex;
      font-weight: 700;

      &__name {
        color: $color-accent-1;
      }

      &__last-name {
        margin-left: 5px;
        color: $color-accent-1;
        text-transform: uppercase;
      }

      &__phone {
        margin-top: 5px;
        margin-bottom: 5px;
        color: $color-accent-2;
      }

      &__email {
        color: $color-accent-2;
      }
    }
  }
}
