@import "../../../assets/styles/styles";

.planning-date-switcher {
  color: $color-white;
  margin-top: 15px;

  &__label {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__main-content {
    display: flex;
    margin-top: 4px;
  }

  &__current-date {
    font-size: 20px;
    font-weight: 500;
  }

  &__actions-container {
    display: flex;
    margin-left: 10px;
  }

  &__change-date-btn {
    outline: 0;
    background: none;
    width: 25px;

    &::after {
      content: "\e901";
      font-family: $font-icomoon;
      font-size: 12px;
      color: $color-accent-3;
      font-weight: 800;
    }

    &--prev {
      &::after {
        content: "\e901";
      }
    }

    &--next {
      margin-left: 4px;

      &::after {
        content: "\e900";
      }
    }

    &:disabled {
      cursor: not-allowed;

      &::after {
        color: $color-secondary-2;
      }
    }
  }
}
