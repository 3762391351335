@import "../../../assets/styles/styles";

.text-input {
  input[type].text-input__input {
    min-height: 44px;
    line-height: 1.4;

    &--error {
      border: 1px solid $color-primary;
    }
  }

  &__error-message {
    margin: 0;
    font-size: $font-size-xs;
    color: $color-primary;
    font-weight: 700;
    margin-top: 5px;
  }
}
