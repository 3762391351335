@import "../../../assets/styles/styles";

.full-screen-pic {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  transition: $transition;

  &--visible {
    opacity: 1;
  }

  &--hidden {
    opacity: 0;
    pointer-events: none;
    transition: $transition;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 60%);
  }

  &__page-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  &__img-wrapper {
    background-color: $color-white;
    z-index: 1;
    padding: 40px;
    box-shadow: $box-shadow;
    position: relative;
    border-radius: 8px;
  }

  &__close-button {
    outline: 0;
    border: none;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(40%, -40%);
    box-shadow: $box-shadow;
    background-color: $color-grey-light;

    &::after {
      content: "\e90d";
      display: inline-block;
      font-family: $font-icomoon;
      font-size: 40px;
      color: $color-black;
    }
  }

  &__img {
    max-width: 60vw;
    max-height: 60vw;
    object-fit: contain;
    min-width: 500px;
  }
}
