@import "../../assets/styles/styles";

$page-title-height: 152px;

.search {
  background-color: $color-secondary-1;

  &__scroll-container {
    overflow: hidden !important;
  }

  &__infinite-scroll {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden !important;

    &--vignette {
      margin-left: -22px;
      margin-top: -22px;
    }
  }

  &__main-content {
    @extend %o-row;

    display: flex;
    padding: 30px 0;
    min-height:
      calc(
        100vh - ($header-height-with-nav + $footer-height + $page-title-height)
      );
  }

  &__content {
    width: 100%;
    margin-top: 20px;

    @include mq($until: tablet) {
      flex-direction: column;
      min-height: 0;
    }
  }

  &__list-content {
    width: 100%;
    padding-left: 30px;
  }

  &__filters {
    width: 300px;
  }

  &__vignette {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  &__view-mode {
    display: flex;
    justify-content: center;
    align-items: center;

    :first-child {
      flex: 1;
      text-align: center;
      cursor: pointer;
      padding-right: 4px;
      border-right: 1px solid rgba($color-white, 0.4);

      &::after {
        content: "\e91d";
        display: inline-block;
        font-family: $font-icomoon;
        font-size: 20px;
        color: #829198;
        transition: $transition;
      }

      &.active::after {
        color: $color-white;
      }
    }

    :last-child {
      flex: 1;
      text-align: center;
      cursor: pointer;
      margin-left: 4px;

      &::after {
        content: "\e91e";
        display: inline-block;
        font-family: $font-icomoon;
        font-size: 20px;
        color: #829198;
        transition: $transition;
      }

      &.active::after {
        color: $color-white;
      }
    }

    padding: $global-padding-xs;
    border: 1px solid $color-white;
    height: 44px;
    width: 76px;
  }

  &__sorting-container {
    min-width: 150px;
  }

  &__count {
    padding: 0 $global-padding-md;
    width: 301px;
    height: 44px;
    border: 1px solid $color-white;
    display: flex;
    align-items: center;
    gap: $global-margin-xxs;

    &--value {
      font-weight: 600;
      font-size: 32px;
    }
  }

  &__header {
    padding: 0;
    height: fit-content;
    width: 100%;
    gap: $global-margin-xxs;
    display: flex;
    justify-content: space-between;
    color: $color-white;
    padding-bottom: 10px;

    &__results {
      display: flex;
      gap: $global-padding-sm;
    }
  }

  &__suggestions {
    padding: $global-padding-lg;

    &--text {
      padding-bottom: $global-padding-xl;
      color: $color-white;
      font-weight: 600;
      font-size: 20px;
    }

    &__list {
      display: flex;
      padding-top: $global-padding-xl;

      &--vignette {
        display: flex;
        gap: 24px;
      }

      &--raw {
        width: fit-content;
        flex-direction: column;
        display: flex;
      }
    }
  }

  // Modal for Vignettes Group Vehicles
  .modal {
    padding-top: 200px;

    .modal__content {
      .carousel-slider {
        .control-dots {
          display: none;
        }

        .control-next.control-arrow::before {
          border-left: 8px solid $color-black;
        }

        .control-prev.control-arrow::before {
          border-right: 8px solid $color-black;
        }

        .control-arrow:hover {
          background: none;
        }

        .slider-wrapper {
          .result-card-wrapper {
            margin-left: 0;
            margin-bottom: 30px;

            .result-card {
              margin: auto;

              &__status {
                flex: none;
              }

              &__stock-nb-container {
                display: none;
              }

              &__main-content {
                text-align: left;
              }
            }
          }
        }
      }
    }

    .modal__footer {
      display: none;
    }
  }
}
