@import "../../../assets/styles/styles";

.footer {
  background-color: $color-primary-light;
  box-shadow: 0 -10px 20px #0000000f;
  min-height: $footer-height;

  &__content-wrapper {
    @extend %o-row;

    padding: 0 10px;
  }

  &__links {
    margin-top: 37px;
    display: flex;
  }

  &__links-column {
    display: flex;
    flex-direction: column;

    & + & {
      margin-left: 150px;
    }
  }

  &__link {
    @extend %paragraph-medium;

    display: flex;
    align-items: center;

    &,
    &:hover,
    &:active,
    &:focus,
    &:visited {
      text-decoration: none;
      color: $color-white;
      outline: 0;
    }

    i {
      font-size: 20px;
      margin-left: $global-margin-sm;
    }
  }

  &__logo-container {
    padding-bottom: $global-padding-sm;
    border-bottom: 1px solid $color-white;
  }

  &__logo {
    width: 300px;
    height: auto;
  }

  &__legal-infos-container {
    margin-top: $global-margin-sm;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__legal-info {
    @extend %paragraph-medium;

    color: $color-white;

    & + & {
      margin-left: $global-margin-sm;
    }

    a {
      color: $color-white;
      text-decoration: none;
    }
  }
}
