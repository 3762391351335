@import "../../../assets/styles/styles";

.page-title {
  background-color: #334147;
  color: $color-white;
  padding: 20px 0;

  &__main-wrapper {
    @extend %o-row;

    display: flex;
    align-items: center;
  }

  &__title-container {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 36px;
    font-weight: 400;
    line-height: 50px;
    padding-right: 40px;
  }

  &__description {
    padding-left: 40px;
    border-left: 1px solid rgba($color-white, 0.4);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    font-style: italic;
  }

  &__action-container {
    text-align: right;
  }

  &__button-label {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 5px;
    white-space: pre-line;
    max-width: 290px;
  }
}
