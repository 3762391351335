@import "../../assets/styles/styles";

$alerts-header-height: 126px;

.alerts-layout {
  &__header {
    background-color: $color-secondary-2;
    display: flex;
    align-items: center;
    min-height: $alerts-header-height;

    &__content-wrapper {
      @extend %o-row;

      display: flex;
      align-items: center;
      flex: 1;
    }

    &__nav {
      color: $color-white;
      font-size: 40px;
    }

    &__config {
      white-space: pre-line;
      color: $color-white;
      font-size: 18px;
      font-style: italic;
      font-weight: 200;
      border-left: 1px solid $color-white;
      padding-left: $global-padding-md;
      margin-left: 100px;
    }

    &__link {
      text-decoration: none;
      border: 1px solid transparent;
      transition: $transition;
      padding: $global-padding-md;

      &--active {
        border: 1px solid $color-grey-light;
        padding: $global-padding-md;
      }
    }
  }

  &__main-content-wrapper {
    display: flex;
    min-height:
      calc(
        100vh - $header-height-with-nav - $footer-height - $alerts-header-height
      );
    background-color: #263238;
  }

  &__menu {
    background-color: $color-secondary-1;
    display: flex;
    justify-content: center;
    padding: 50px 0;
    color: $color-white;
    width: 350px;

    &__home {
      &::after {
        content: "\e902";
        display: inline-block;
        font-family: $font-icomoon;
        font-size: 70px;
        color: inherit;
      }
    }

    nav {
      display: flex;
      flex-direction: column;
      gap: $global-padding-md;
      list-style-type: none;
      font-size: 18px;

      a {
        display: flex;
        align-items: center;
        gap: $global-padding-sm;
        color: $color-white;
        text-decoration: none;
        outline: none;
      }
    }
  }

  &__content {
    background-color: $color-white;

    // flex: 1;
    width: 60%;
  }
}
