@import "../../../assets/styles/styles";

.group-vehicles {
  transition: $transition;

  .row:first-child {
    transition: $transition;
    opacity: 1;
  }

  .row:not(:first-child) {
    display: none;

    .row__date-container {
      color: transparent;
    }
  }

  .row-card__container {
    &__more {
      width: 220px;
      padding-left: 20px;
      padding-right: 20px;
      text-align: right;
      font-weight: 700;
      font-size: 18px;
      height: 45px;
      line-height: 45px;
      cursor: pointer;

      &::after {
        content: "\e908";
        font-family: $font-icomoon;
        font-size: 8px;
        color: $color-black;
        transition: $transition;
        margin-left: 20px;
        display: inline-block;
        vertical-align: middle;
      }

      &--opened::after {
        transform: rotate(180deg);
      }
    }
  }

  &--opened {
    margin: 5px 0;

    .row:first-child {
      transition: $transition;
      opacity: 0.8;
    }

    .row:not(:first-child) {
      display: flex;
      opacity: 1;
    }
  }
}
