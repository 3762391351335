@import "../../assets/styles/styles";

.poc {
  background-color: $color-secondary-1;
  min-height: calc(100vh - 442px);

  h1 {
    margin: 50px 0;
    color: $color-white;
  }

  &__main-container {
    @extend %o-row;

    padding-bottom: 50px;
  }
}

.scroll-table {
  display: flex;

  &__header {
    display: flex;
    width: 100%;
  }

  .scroll-table__header {
    border-right: 2px solid $color-black;
  }

  &__row {
    display: flex;
    width: 100%;
  }

  &__fixed-content {
    max-height: 500px;

    .scroll-table__body .scroll-table__row {
      border-right: 2px solid $color-black;
    }
  }

  &__scrollable-content {
    max-height: 500px;
    overflow-x: auto;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 53px;
    padding: 0 20px;
    line-height: 16px;
    vertical-align: middle;
    font-size: 14px;
    text-align: center;
    background: #f5f5f5;
    border: 1px solid #e4e4e4;
    text-transform: uppercase;
    color: #829198;
    font-weight: 700;
    width: 160px;
    flex-shrink: 0;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    padding: 0 20px;
    line-height: 16px;
    vertical-align: middle;
    font-size: 14px;
    text-align: center;
    border-bottom: 1px solid #e4e4e4;
    width: 160px;
    flex-shrink: 0;
    background: #f5f5f5;
    color: #829198;
  }
}
