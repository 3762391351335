@import "../../assets/styles/styles";

.privacy-policy {
  background-color: $color-white;

  &__content {
    padding: 20px 0;
    max-width: 1400px;
    margin: auto;
  }

  & h2,
  & h3 {
    margin-bottom: 5px;
    text-decoration: underline;
  }

  & ul {
    list-style: initial;
  }

  &__box {
    padding: 5px;
  }

  &__table {
    border-collapse: collapse;
    width: 100%;

    & tr {
      height: 62px;
    }

    & td {
      width: 30%;
      height: 62%;
    }
  }
}
