@import "../../../assets/styles/styles";

.users {
  padding: $global-padding-xl;

  &__title {
    @extend %title-2;
  }

  &__form {
    margin-top: $global-margin-xl;
    width: fit-content;
  }

  &__form-sections-wrapper {
    display: flex;
    width: fit-content;
  }

  &__form-section {
    margin-top: $global-margin-lg;
    width: 500px;

    &:first-child {
      padding-right: 100px;
      border-right: 1px solid $color-grey-medium;
    }

    &:last-child {
      padding-left: 100px;
    }

    .o-form-group {
      margin-top: 10px;
    }
  }

  &__form-validation {
    display: flex;
    justify-content: center;
    margin: $global-margin-xl 0;

    button:last-child {
      margin-left: $global-margin-sm;
    }
  }

  .filterable-table {
    margin-top: $global-margin-xl;

    .filterable-table__col:nth-child(1),
    .filterable-table__col:nth-child(2),
    .filterable-table__col:nth-child(3),
    .filterable-table__col:nth-child(4) {
      width: 10%;
    }

    .filterable-table__col:nth-child(3) {
      width: 16%;
    }

    .text-input input[type].text-input__input {
      width: 120px;
    }
  }

  &__modal-text {
    @extend %paragraph-medium;

    text-align: center;
  }
}
