@import "../../../assets/styles/styles";

.filterable-table {
  width: 100%;

  td,
  th {
    word-break: break-word;
  }

  &__head {
    border-bottom: 2px solid $color-black;

    tr {
      th {
        text-align: left;
      }
    }
  }

  &__body {
    tr {
      border-bottom: 1px solid $color-grey-medium;
      transition: $transition;

      td {
        text-align: left;
        padding: $global-padding-md;

        @extend %paragraph-small;

        &.capitalize {
          text-transform: capitalize;
        }
      }

      &:hover {
        background-color: $color-grey-ultralight;
      }
    }
  }

  &__column-button {
    background: none;

    @extend %paragraph-medium;

    font-weight: 600;
    padding: $global-padding-md;

    &:active,
    &:hover,
    a:focus {
      outline: 0;
    }
  }

  &__direction-btn {
    display: inline-block;
    width: 8px;
    height: 20px;
    position: relative;
    vertical-align: middle;
    background: none;

    &:first-child {
      margin-left: 5px;
    }

    &::after {
      display: block;
      font-family: $font-icomoon;
      font-size: 20px;
      color: $color-grey-medium;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &--asc::after {
      content: "\e90f";
    }

    &--desc::after {
      content: "\e910";
    }

    &--active::after {
      color: $color-black;
    }
  }

  &__actions-container {
    white-space: nowrap;
  }
}
