@use "sass:color";
@import "../../../assets/styles/styles";

.planning-calendar {
  width: 300px;
  background-color: $color-white;
  padding: 0 15px 15px;
  color: $color-black;
  border-radius: 10px;

  &__header {
    padding: 10px 0;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    display: flex;
    width: 20px;
    height: 20px;
    position: relative;

    &::after {
      content: "\e900";
      display: block;
      font-family: $font-icomoon;
      font-size: 12px;
      transition: $transition;
      position: absolute;
      left: 0;
      color: $color-grey-dark;
    }

    &--left {
      background: none;

      &::after {
        top: 0;
        transform: rotate(180deg);
      }
    }

    &--right {
      background-color: $color-white;

      &::after {
        top: 3px;
        right: 0;
      }
    }
  }

  &__date-range {
    display: flex;
    height: 30px;
    font-weight: 600;
    font-size: 20px;
    align-items: center;
    color: $color-black;
  }

  &__title {
    padding: 0 10px;
    display: flex;
    position: relative;
  }

  &__form-title {
    width: 168px;
    height: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-top: 6px;
    font-style: italic;
    color: $color-black;
  }

  &__hr {
    border-top: 1px solid $color-grey-light;

    &--calendar {
      margin-top: 3px;
    }
  }

  &__loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 220px;
  }
}
