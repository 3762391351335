@import "../../assets/styles/styles";

.quotes {
  background-color: $color-secondary-1;
  min-height: calc(100vh - 442px);

  &__main-wrapper {
    @extend %o-row;

    padding: 38px 0;
  }
}
