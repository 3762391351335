@import "../../../assets/styles/styles";

.result-card-wrapper {
  position: relative;
  margin-left: 22px;
  margin-top: 22px;
  z-index: 0;
}

.result-card {
  width: 302px;
  height: 470px;
  background-color: #44565f;
  position: static;
  font-family: $font-family-main;
  border-radius: 8px;
  box-shadow: -4px -2px 6px 3px rgb(0 0 0 / 40%);

  &__overlay {
    width: 302px;
    height: 470px;
    background-color: #44565f;
    position: absolute;
    top: -10px;
    left: -10px;
    border-radius: 8px;
    z-index: -1;
  }

  &__overlay-header {
    height: 39px;
    width: 100%;
    background-color: $color-green;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    &--available {
      background-color: $color-green;
    }

    &--ordered {
      background-color: $color-blue;
    }

    &--production {
      background-color: $color-blue;
    }

    &--configurable {
      background-color: $color-primary;
    }
  }

  &__overlay-img {
    height: 136px;
    width: 100%;
    background-color: #cbcccd;
  }

  &__stock-nb-container {
    background-color: #325175;
    color: #e4e4e4;
    width: 128px;
    position: absolute;
    top: 0;
    right: 20px;
    height: 60px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    align-items: center;
    padding: 16px 10px;
    justify-content: space-between;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  }

  &__stock-nb {
    font-size: 18px;
    font-weight: 800;
    line-height: 20px;
  }

  &__stock-status {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    margin-left: 10px;
  }

  &__header {
    display: flex;
    align-items: center;
    color: #e4e4e4;
    padding: 10px 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    min-height: 52px;

    &--available {
      background-color: $color-green;
    }

    &--ordered {
      background-color: $color-blue;
    }

    &--production {
      background-color: $color-blue;
    }

    &--configurable {
      background-color: $color-primary;
    }
  }

  &__status {
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    flex: 1;
  }

  &__date {
    width: 92px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    font-family: $font-family-tertiary;
    text-align: right;
  }

  &__img-container {
    padding: 20px;
    background-color: #e4e4e4;
    cursor: pointer;
  }

  &__img {
    width: 100%;
    height: auto;
    overflow-x: hidden;
  }

  &__background-img-container {
    padding: 20px;
    background-color: #e4e4e4;
    cursor: pointer;
    height: 139px;
    width: 100%;
  }

  &__background-img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__main-content {
    padding: 0 21px;
  }

  &__brand {
    margin-top: 16px;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: #e4e4e4;
    text-transform: uppercase;
  }

  &__config-name {
    margin-top: 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #e4e4e4;
    min-height: 20px;
  }

  &__model {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #e4e4e4;
  }

  &__footer {
    margin-top: 37px;
    display: flex;
    padding: 0 21px;
  }

  &__various-infos-container {
    display: flex;
    flex: 1;
  }

  &__item-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    & + & {
      margin-left: 14px;
    }
  }

  &__item-icon {
    width: 28px;
    height: 28px;
    background-color: #c4c4c4;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      display: block;
      content: "";
      font-family: $font-icomoon;
      font-size: 20px;
      color: $color-black;
    }

    &--seats::after {
      content: "\e920";
    }

    &--beds::after {
      content: "\e921";
      font-size: 14px;
    }

    &--length::after {
      content: "\e91f";
      font-size: 26px;
    }
  }

  &__item-nb {
    position: absolute;
    bottom: 2px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #e4e4e4;
  }

  &__price-container {
    flex: 1;
    text-align: right;
    color: #e4e4e4;
    margin-top: 5px;
  }

  &__price-label {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    font-style: italic;
  }

  &__price {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
  }

  &__btn-container {
    text-align: center;
    margin-top: 30px;
  }

  &__btn {
    padding: 12px 23px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #263238;
    border-radius: 50px;
    background-color: #f5f5f5;
    outline: 0;
    border: 0;
  }

  /*
    &--content {
        position: relative;
        z-index: 10;
        height: 100%;
        background-color: $color-secondary-2;
        box-shadow: rgb(0 0 0 / 35%) 0 5px 15px;
        overflow: hidden;
        border-radius: $border-radius-md;
        width: 302px;
        //height: 470px;
    }

    &__overlay {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        left: 0;
        border-radius: $border-radius-md;
        height: 400px;
        background-color: white;
        transform: translate(-15px, -15px);
        z-index: 0;
        width: 400px;

        &--head {
            height: 40px;
        }

        &--img {
            background-color: $color-grey-light;
            height: 170px;
        }

        &--content {
            background-color: $color-secondary-2;
            flex: 1;
        }
    }

    &--seat {
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
            content: "\e913";
            display: inline-block;
            font-family: $font-icomoon;
            font-size: 17px;
            color: $color-black;
        }
    }

    &--bed {
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
            content: "\e914";
            display: inline-block;
            font-family: $font-icomoon;
            font-size: 17px;
            color: $color-black;
        }
    }

    &--length {
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
            content: "\e915";
            display: inline-block;
            font-family: $font-icomoon;
            font-size: 17px;
            color: $color-black;
        }
    }

    &--icon-wrapper {
        display: flex;
        flex-direction: column;
        gap: $global-padding-sm;
        align-items: center;

        :first-child {
            width: 28px;
            height: 28px;
            border-radius: $border-radius-lg;
            background-color: $color-grey-light;
        }
    }

    flex: none;
    border-radius: $border-radius-sm;
    height: 400px;

    &__img-wrapper {
        padding: $global-padding-lg;
        background-color: $color-grey-light;
        height: 144px;
    }

    &__description {
        color: $color-grey-light;
        padding: $global-padding-sm $global-padding-lg;

        &--mark {
            padding-bottom: $global-padding-sm;
            font-size: $global-font-size;
            text-transform: uppercase;
            font-weight: 700;
        }
    }

    &__configs {
        display: flex;
        padding: 0 41px;

        :first-child {
            background-color: $color-white;
            padding: $global-padding-md $global-padding-sm;
            border-radius: $border-radius-lg;
            margin: 10px 0;
            color: $color-secondary-1;
            font-size: $global-font-size;
            font-weight: 600;
            cursor: pointer;
            text-align: center;
            width: 100%;
        }
    }

    &__infos {
        color: $color-grey-light;
        display: flex;
        padding: 0 24px;

        &--specs {
            flex: 1;
            display: flex;
            gap: 17px;
        }

        &--price {
            text-align: right;
            flex: 1;

            :first-child {
                font-style: italic;
            }

            :last-child {
                font-weight: 600;
                font-size: 32px;
            }
        }
    }

    &--img {
        background: url("../../../assets/img/camping.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        height: 100%;
    }

    &__head {
        color: $color-white;
        justify-content: space-between;
        display: flex;
        align-items: center;
        width: 400px;
        white-space: nowrap;
        text-overflow: ellipsis;

        &--delivery-infos {
            font-size: 12px;
        }

        &--available {
            background-color: $color-green;
            padding: $global-padding-sm $global-padding-lg;
        }

        &--ordered_factory {
            background-color: $color-black;
            padding: $global-padding-sm $global-padding-lg;
        }

        &--available_factory {
            background-color: $color-green;
            padding: $global-padding-sm $global-padding-lg;
        }

        &--in_production {
            background-color: $color-blue;
            padding: $global-padding-sm $global-padding-lg;
        }

        &--to_configure {
            background-color: $color-primary;
            padding: $global-padding-sm $global-padding-lg;
        }
    }
    */
}
