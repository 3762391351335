@import "../../../assets/styles/styles";

.header-dropdown {
  min-width: 180px;
  max-width: 260px;
  position: relative;
  z-index: 50;
  height: $header-height;


  &__selector-wrapper {
    display: flex;
    align-items: center;
    color: $color-white;
    background: none;
    padding: 0;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: $color-secondary-1;
    z-index: 51;

    &:hover,
    &:active,
    &:focus {
      color: $color-white;
    }
  }

  &__icon {
    background: none;
    outline: 0;

    &::before {
      content: "\e904";
      display: block;
      font-family: $font-icomoon;
      font-size: 30px;
      color: $color-accent-3;
    }
  }

  &__userinfos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 2px;
    margin-right: auto;
    flex-grow: 1; /* This makes the user infos take available space */
  }

  &__username {
    font-size: $font-size-md;
    line-height: 1.18;
    font-weight: 400;
    font-family: $font-family-tertiary;
    max-width: 100%;

    @extend %u-text-ellipsis;

    text-transform: capitalize;
  }

  &__usercompany {
    font-size: 12px;
    line-height: $line-height-xs;
    font-weight: 400;

    @extend %u-text-ellipsis;
  }

  &__selector-button {
    background: none;
    padding: 0;
    width: 12px;
    margin-bottom: 6px;
    position: relative;
    margin-right: 33px;
    flex-shrink: 0;

    &::after {
      content: "\e908";
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      font-family: $font-icomoon;
      font-size: 6px;
      color: $color-accent-3;
      transition: $transition;
    }

    &--opened::after {
      transform: rotate(180deg);
    }
  }

  &__dropdown {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    box-shadow: $box-shadow;
    transform: translateY(-100%);
    transition: all 0.4s ease-in-out;
    border-top: 5px solid transparent;
    z-index: -1;
    opacity: 0;

    &--opened {
      transform: translateY(100%);
      opacity: 1;
    }
  }

  &__dropdown-userinfos {
    background-color: $color-white;
    color: $color-black;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: $global-padding-lg;
    text-align: center;
    padding-bottom: $global-margin-sm;
    cursor: pointer;

    &::before {
      content: "\e904";
      display: block;
      font-family: $font-icomoon;
      font-size: 40px;
      color: $color-accent-3;
    }
  }

  &__dropdown-username {
    font-size: $font-size-lg;
    line-height: $line-height-md;
    font-weight: 600;
    font-family: $font-family-tertiary;
    padding: 0 $global-padding-md;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    text-transform: capitalize;
  }

  &__dropdown-usercompany {
    font-size: $font-size-xs;
    line-height: $line-height-md;
    font-weight: 400;
    font-family: $font-family-tertiary;
    margin-top: $global-margin-xxs;
    padding: 0 $global-padding-sm;
  }

  &__links-container {
    padding: $global-padding-sm 0;
    background-color: $color-grey-light;

    // color: $color-grey-dark;
    color: $color-black;
    display: flex;
    flex-direction: column;
  }

  &__link {
    font-size: $font-size-xxs;
    line-height: $line-height-md;
    font-weight: 500;
    font-family: $font-family-main;
    padding: $global-padding-sm;
    display: flex;
    align-items: center;
    transition: $transition;
    text-transform: uppercase;

    &,
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: $color-secondary-1;
      outline: 0;
      border: none;
    }

    i {
      font-size: 20px;
      margin-right: $global-margin-xs;
      min-width: 20px;
      line-height: 0;
    }

    .icon-account-group {
      padding-bottom: 3px;
    }

    &.disabled {
      pointer-events: none; 
      cursor: not-allowed;      
      color: $color-grey-dark; 

      &:hover,
      &:active,
      &:focus {
        color: $color-grey-dark; 
      }
    }
  }

  &__disconnect {
    width: 100%;
    padding: $global-padding-sm;
    font-size: $font-size-xxs;
    line-height: $line-height-md;
    font-weight: 400;
    font-family: $font-family-main;
    color: $color-white;
    background-color: $color-accent-1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;

    &:active,
    &:hover,
    &:focus {
      color: $color-white;
    }

    &::before {
      content: "\e909";
      display: inline-block;
      font-family: $font-icomoon;
      font-size: 20px;
      color: $color-white;
      margin-right: 10px;
      margin-bottom: 2px;
    }
  }
}
