@import "../../../assets/styles/styles";

.icon {
  &::after {
    content: "\e936";
    display: inline-block;
    font-family: $font-icomoon;
    padding-top: 5px;
    font-size: 25px;
    color: $font-icomoon;
    transition: $transition;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
