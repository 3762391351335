@import "../../assets/styles/styles";

.legal-infos {
  background-color: $color-white;

  &__content {
    padding: 20px 0;
    max-width: 1400px;
    margin: auto;
  }

  & h2 {
    margin-bottom: 5px;
    text-decoration: underline;
  }

  &__box {
    padding: 5px;
  }
}
