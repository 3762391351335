@import "../../../assets/styles/styles";

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  padding: 0 16px;
  gap: 15px;
  background-color: $color-white;
  border-bottom: 1px solid #263238;

  &:nth-child(even) {
    background-color: #f5f5f5;
  }

  &__brand-container {
    width: 13%;
  }

  &__brand-title {
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;
  }

  &__config-name {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-top: 4px;
  }

  &__model-container {
    font-size: 16px;
    font-weight: 400;
    width: 13%;
    word-break: break-word;
    hyphens: auto;
  }

  &__price-container {
    width: 13%;
  }

  &__price-label {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    font-style: italic;
  }

  &__price {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    margin-top: 4px;
  }

  &__date-container {
    width: 15%;

    &--is-planning {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    &--available {
      color: $color-green;
    }

    &--ordered {
      color: $color-blue;
    }

    &--production {
      color: $color-blue;
    }

    &--configurable {
      color: $color-primary;
    }
  }

  &__configurability-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__status {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  &__date {
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
    line-height: 16px;
    margin-top: 4px;
  }

  &__img-container {
    padding: 10px;
    cursor: pointer;
  }

  &__img {
    width: 180px;
    height: auto;
    display: block;
  }

  &__img-background-container {
    padding: 10px;
    width: 180px;
    height: 80px;
    cursor: pointer;
  }

  &__img-background {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__btn-container {
    width: 220px;

    .button.button--rounded {
      min-width: 220px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
