@import "../../../assets/styles/styles";

.point-of-sale-modal {
  background-color: $color-white;
  transition: $transition;
  position: relative;
  padding: 15px;
  border-radius: 6px;

  &__group-label {
    padding-bottom: 15px;
  }

  &__point-of-sale-text-input {
    padding-top: 15px;
    color: $color-secondary-4;
  }

  &__save-btn-container {
    text-align: right;
  }

  &__save-btn {
    padding: 5px 10px;
    border: 1px solid $color-secondary-4;
    border-radius: 8px;
    margin-top: 10px;

    &:hover {
      border: 1px solid $color-secondary-4;
    }

    &:disabled {
      background-color: $color-grey-medium;
      opacity: 0.5;
    }
  }

  .text-input__input {
    width: 100%;
    margin-bottom: 10px;
  }
}
