@import "../../../assets/styles/styles";

.global-search-order-result {
  display: flex;
  color: initial;
  text-decoration: none;

  &__column {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    padding: 4px;
    line-height: 16px;
    vertical-align: middle;
    font-size: 14px;
    text-align: center;
    border: 1px solid #e4e4e4;
    border-top: none;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    width: 48px;

    &:not(:last-child) {
      border-right: none;
    }
  }
}
