@import "../../../assets/styles/styles";

.point-of-sale-stock {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  padding: 20px;

  &__dealer-pointOfSale {
    width: 360px;
    background-color: $color-white;
    padding: 10px;
    border: 1px solid gray;
  }
}
