@import "../../../assets/styles/styles";

.modal-request-coc-order {
  &__title {
    color: $color-accent-1;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin: 10px 0;
    padding-bottom: 10px;
  }

  &__form {
    text-align: center;
    padding: $global-padding-md 0 0;

    input.text-input__input {
      min-height: 35px !important;
      margin-bottom: 5px;
      background-color: $color-grey-ultralight;
      border-radius: 10px;
      border: 2px solid $color-grey-medium;
    }
  }

  button {
    margin: 20px 10px;
    padding: $global-padding-sm $global-padding-md;
    font-size: $font-size-lg;
    font-weight: 600;
    text-align: center;
    min-width: 180px;
    border-radius: $border-radius-lg;

    &.save-btn {
      &,
      &:active,
      &:focus {
        background-color: $color-accent-1;
        color: $color-white;
      }

      &:hover {
        color: $color-white;
        background-color: $color-accent-2;
      }

      &:disabled {
        color: $color-white;
        background-color: $color-accent-2;
      }
    }

    &.cancel-btn {
      &,
      &:active,
      &:focus {
        background-color: $color-grey-ultralight;
        color: $color-secondary-1;
      }

      &:hover {
        background-color: $color-grey-medium;
        color: $color-secondary-1;
      }

      &:disabled {
        background-color: $color-grey-medium;
        color: $color-secondary-1;
      }
    }
  }
}
