@import "../../assets/styles/styles";

.planning {
  background-color: $color-secondary-1;

  &__main-content {
    @extend %o-row;

    display: flex;
    padding: 30px 0;
    min-height: calc(100vh - ($header-height + $footer-height + $page-title-height));
  }

  &__calendar {
    background-color: $color-white;
    width: 300px;
    border-radius: 10px;
    height: 100%;
  }

  &__results {
    flex: 1;
  }
}
