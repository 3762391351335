@import "../../assets/styles/styles";

.orders {
  background-color: $color-secondary-1;
  min-height: calc(100vh - 442px);

  &__main-wrapper {
    @extend %o-row;

    padding: 15px 0;
  }

  &__actions-wrapper {
    display: flex;
    padding-bottom: 15px;
    align-items: center;
  }

  &__searchbar-wrapper {
    margin-left: 50px;

    .searchbar {
      input[type].searchbar__input {
        border-radius: 8px;
        height: 40px;
        width: 360px;
        padding-left: 46px;
      }

      &__submit-button {
        top: 5px;
        left: 10px;
      }
    }
  }

  &__export {
    $self: &;

    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;

    &:hover {
      border-radius: 8px 8px 0 0;
      background-color: $color-grey-light;

      & > #{$self}-button:after {
        transform: rotate(180deg);
      }

      & > #{$self}-options {
        display: flex;
      }
    }
  }

  &__export-button {
    background-color: $color-grey-ultralight;
    color: $color-secondary-2;
    border-radius: 8px;
    font-size: 18px;
    line-height: 20px;
    border: 1px solid $color-grey-dark;
    width: 180px;
    transition: $transition;
    padding: 8px;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    &::after {
      content: "\e908";
      display: inline-block;
      font-family: $font-icomoon;
      font-size: 6px;
      margin-left: 6px;
      color: $color-secondary-3;
      transition: $transition;
    }
  }

  &__export-options {
    display: none;
    width: 100%;
    top: 100%;
    position: absolute;
    flex-direction: column;
    border-radius: 0 0 8px 8px;
    background-color: $color-grey-light;
    gap: 5px;
    z-index: 13;
    padding: 5px 0;
  }

  &__export-option {
    display: flex;
    align-items: center;
    background-color: transparent;
    color: $color-secondary-2;
    height: 40px;
    font-size: 16px;
    line-height: 1.25;
    position: relative;
    padding: 10px 30px;
    transition: $transition;

    &::after {
      font-family: $font-icomoon;
      content: "\e931";
      display: inline-block;
      font-size: 16px;
      right: 30px;
      position: absolute;
    }
  }
}
